import request from '@/utils/request';

//登录
export const UserLogin = (params: object) => {
  return request({
    url: '/userUi/login',
    method: 'post',
    params,
  });
};
//获取所有格子
export const RockingDiceApi = (params: object) => {
  return request({
    url: '/cellUi/rockingSieve',
    method: 'post',
    data: params,
  });
};
//获取所有格子
export const FindAllCellApi = (params: object) => {
  return request({
    url: '/cellUi/findAll',
    method: 'post',
    data: params,
  });
};

//获取用户道具和机会
export const GetUserDataInfo = (params: object) => {
  return request({
    url: "/userUi/userNUM",
    method: 'post',
    params,
  })
}

//领取游戏机会(每日登录)
export const GetGameOpportunityApi = (params: object) => {
  return request({
    url: '/userUi/logInCollect',
    method: 'post',
    params,
  });
};
//领取游戏机会(游戏道具)
export const GetGamePropsApi = (params: object) => {
  return request({
    url: '/userUi/gameProp',
    method: 'post',
    params,
  });
};
//领取游戏机会(慈爱捐赠)
export const GetLovingDonationApi = (userId: number) => {
  return request({
    url: '/userUi/lovingDonation',
    method: 'post',
    data: userId,
  });
};
//获取用户道具
export const GetUserPropsApi = (params: object) => {
  return request({
    url: '/userUi/userProp',
    method: 'post',
    params,
  });
};
//用户使用钥匙抽奖
export const KeyLotteryPrizeList = (params: object) => {
  return request({
    url: '/prizeUi/findAllPrize',
    method: 'post',
    params,
  });
};
//用户使用钥匙抽奖
export const UserHandleLotteryApi = (params: object) => {
  return request({
    url: '/prizeUi/lottery',
    method: 'post',
    params,
  });
};

//获取彩蛋
export const GetBrandApi = () => {
  return request({
    url: '/brandUi/findAll',
    method: 'post',
  });
};

//使用骰子增加用户机会
export const UsePropAddGameNum = (params: object) => {
  return request({
    url: '/userUi/usePropAddGameNum',
    method: 'post',
    data: params,
  });
};


//我的钥匙格子情况
export const getKeyLatticeList = (params: object) => {
  return request({
    url: '/userUi/userGetKeyCell',
    method: 'post',
    params,
  });
};
