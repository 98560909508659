<template>
  <router-view />
</template>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

#app {
  height: 100%;
}
</style>
