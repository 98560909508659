import { createRouter, createWebHashHistory,createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/minePrize',
    name: 'minePrize',
    component: () => import(/* webpackChunkName: "about" */ '../views/MinePrize.vue')
  },
  {
    path: '/qrlist',
    name: 'qrlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/QrList.vue')
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: () => import(/* webpackChunkName: "about" */ '../views/Qrcode.vue')
  }
]

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
