import axios from 'axios';
// import { Local } from './storage';
import { Local, Session } from '@/utils/storage';
import { showDialog } from "vant"
import router from "@/router/index"

let baseURL = window.gurl.SERVICE_CONTEXT_PATH;
const env = process.env.NODE_ENV
if (env === 'development') baseURL = "/api"

// 配置新建一个 axios 实例
const service = axios.create({
	baseURL,
	timeout: 50000,
	headers: { 'Content-Type': 'application/json' },
});
service.interceptors.request.use(
	(config) => {
		if (Session.get('TFT-Token')) {
			config.headers['token'] = Session.get('TFT-Token');
		}
		return config;
	},
	(error) => {
		return Promise.reject(error.response);
	}
);

// 添加响应拦截器
service.interceptors.response.use((response: any) => {
	// 对响应数据做点什么
	const res = response.data;
	if (res.code === -100) {
		Local.clear(); // 清除浏览器全部临时缓存
		router.push("/login")
	} else if (res.code === 200 || !res.code) {
		return response.data;
	}else {
		// return Promise.reject(service.interceptors.response);
	}
}, (error: any) => {
	// 对响应错误做点什么
	if (error.message.indexOf('timeout') != -1) {
		showDialog({ title: '提示', message: '网络超时' });
	} else if (error.message == 'Network Error') {
		showDialog({ title: '提示', message: '网络连接错误' });
	} else {
		showDialog({ title: '提示', message: error.message });
	}
	return Promise.reject(error);
}
);


// 导出 axios 实例
export default service;
